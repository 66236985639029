import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../../../components/seo';

import Contact from '../../../components/contact';
import Hero from '../../../components/hero';
import Layout from '@src/components/layout';
import MarkdownContent from '../../../components/markdown-content/MarkdownContent';
import { RockerSavingsAllmannaVillkorQueryQuery } from '../../../../graphql-types';


const AllmannaVillkor = ({ data }) => {
  const {
    contentfulPage: {
      headLine,
      pageHeader,
      pageHeaderDescription: { pageHeaderDescription },
      pageHeaderSubtitle: { pageHeaderSubtitle },
    },
  } = data as RockerSavingsAllmannaVillkorQueryQuery;
  
  return (
    <Layout>
      <SEO title={headLine} />
      <Hero title={pageHeader} subtitleMarkdown={pageHeaderDescription} />
      <div className="container">
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <MarkdownContent markdown={pageHeaderSubtitle} />
        </div>
      </div>
      <Contact hideFaq />
    </Layout>
  );
};

export default AllmannaVillkor;

export const query = graphql`
  query RockerSavingsAllmannaVillkorQuery {
    contentfulPage(pageUrl: { eq: "/allmanna-villkor/savings/allmannavillkor" }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }

      pageHeaderSubtitle {
        pageHeaderSubtitle
      }

      pageContent {
        ... on ContentfulDescriptionComponent {
          id
          bodyText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
